import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
} from '@mui/material';

type MenuItemType = {
  defaultProps?: ComponentsProps['MuiMenuItem'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiMenuItem'];
  variants?: ComponentsVariants['MuiMenuItem'];
};

export default {
  styleOverrides: {
    root: {
      font: 'normal normal 500 14px/24px "Circular Std"',
      '&:hover': {
        backgroundColor: '#E0E6E8',
      },
      '&.Mui-selected:hover': {
        backgroundColor: '#E0E6E8',
      },
      '&.Mui-selected.Mui-focusVisible': {
        backgroundColor: '#E0E6E8',
      },
      '&.Mui-selected': {
        backgroundColor: '#EEF2F7',
      },
    },
  },
} as MenuItemType;

import { WebStorageStateStore } from 'oidc-client-ts';
import { AuthProviderProps } from 'react-oidc-context';
import { UserManager } from './UserManager';
import { cookieStorage } from './cookie-storage';

export const OIDC_CONFIG: AuthProviderProps = {
  client_id: process.env.REACT_APP_CLIENT_ID || '',
  redirect_uri: window.location.href,
  response_type: 'code',
  post_logout_redirect_uri: `${process.env.REACT_APP_USER_MANAGEMENT_URL}`,
  scope: 'openid token',
  authority: `${process.env.REACT_APP_USER_SERVICE_URL}/api/v1`,
  silent_redirect_uri: window.location.host,
  automaticSilentRenew: false,
  loadUserInfo: true,
  userStore: new WebStorageStateStore({ store: cookieStorage, prefix: '' }),
  implementation: UserManager,
};

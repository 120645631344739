import { ToastOptions, toast as baseToast } from 'react-toastify';
import { Toast } from '../components/Toast/Toast';

export const toast = {
  success: (message: string, options: ToastOptions = {}): React.ReactText =>
    baseToast.success(Toast, { ...options, data: { message } }),

  error: (message: string, options: ToastOptions = {}): React.ReactText =>
    baseToast.error(Toast, { ...options, data: { message } }),

  warning: (message: string, options: ToastOptions = {}): React.ReactText =>
    baseToast.warning(Toast, { ...options, data: { message } }),

  info: (message: string, options: ToastOptions = {}): React.ReactText =>
    baseToast.info(Toast, { ...options, data: { message } }),
};

export enum NAV_NAMES {
  BL_CONNECT = 'BL Connect',
  FAVORITES = 'Favorites',
  HELP_SUPPORT = 'Help & Support',
  INSTRUCTIONAL_STRATEGIES = 'Instructional Strategies',
  LESSON_PLANS = 'Lesson Plans',
  LOG_OUT = 'Log Out',
  RESOURCE_CENTER = 'BetterLesson Blog',
  RESOURCES = 'Resources',
  SEARCH = 'Strategies & Lessons',
  SETTINGS = 'Settings',
  STUDENT_CENTERED_TEACHING = 'Student-Centered Teaching and Learning',
  USER_MANAGEMENT = 'User Management',
  USER = 'User',
}

import { NAV_NAMES } from '../constants/nav-names';
import { NavLinkType } from './types';

export const USER: NavLinkType = {
  menuName: NAV_NAMES.USER,
  menuItems: [
    {
      name: NAV_NAMES.SETTINGS,
      href: `${process.env.REACT_APP_USER_MANAGEMENT_URL}/profile`,
    },
    {
      name: NAV_NAMES.FAVORITES,
      href: `${process.env.REACT_APP_LAB_URL}/browse/favorites`,
    },
    {
      name: NAV_NAMES.HELP_SUPPORT,
      href: '//betterlesson.scrollhelp.site/',
    },
    {
      name: NAV_NAMES.LOG_OUT,
      href: `${process.env.REACT_APP_LAB_URL}/logout`,
    },
  ],
};

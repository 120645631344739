import { PaletteOptions } from '@mui/material';

export default {
  primary: {
    main: '#1976d2',
    dark: '#1565c0',
    light: '#42a5f5',
    contrastText: '#ffffff',
  },
  secondary: {
    main: '#08bcc3',
    dark: '#0e9698',
    light: '#51d0d0',
    contrastText: '#ffffff',
  },
  text: {
    primary: '#222222',
    secondary: '#636363',
    disabled: 'rgba(34, 34, 34, 0.61)',
  },
  textDark: {
    high: '#222222',
    medium: '#636363',
    low: 'rgba(34, 34, 34, 0.61)',
  },
  textLight: {
    high: '#ffffff',
    medium: 'rgba(255, 255, 255, 0.7)',
    low: 'rgba(255, 255, 255, 0.5)',
  },
  action: {
    active: 'rgba(61, 61, 61, 0.54)',
    hover: 'rgba(61, 61, 61, 0.04)',
    disabled: 'rgba(61, 61, 61, 0.26)',
    selected: '#e1f5fe',
    focus: 'rgba(61, 61, 61, 0.12)',
    disabledBackground: 'rgba(61, 61, 61, 0.12)',
  },
  error: {
    main: '#d31542',
    dark: '#ac013e',
    light: '#f05d78',
    contrastText: '#ffffff',
    alertText: '#8a003a',
  },
  warning: {
    main: '#ff8b00',
    dark: '#e55300',
    light: '#ffaa00',
    contrastText: '#ffffff',
    alertText: '#d54500',
  },
  info: {
    main: '#0288d1',
    dark: '#01579b',
    light: '#03a9f4',
    contrastText: '#ffffff',
    alertText: '#0d3c61',
  },
  success: {
    main: '#209348',
    dark: '#15823e',
    light: '#33b55f',
    contrastText: '#ffffff',
    alertText: '#00632a',
  },
  divider: 'rgba(0, 0, 0, 0.12)',
  other: {
    divider: 'rgba(0, 0, 0, 0.12)',
    backdropBackground: 'rgba(0, 0, 0, 0.5)',
    tooltipBackground: 'rgba(97, 97, 97, 0.9)',
    snackbarBackground: '#b3e5fc',
  },
  fim: {
    main: '#f0b323',
    dark: '#ef911a',
    light: '#f2c93a',
    contrastText: '#222222',
  },
  cac: {
    main: '#bf0d3e',
    dark: '#ac013e',
    light: '#e31c43',
    contrastText: '#ffffff',
  },
  il: {
    main: '#ff6900',
    dark: '#e55300',
    light: '#ff9c00',
    contrastText: '#ffffff',
  },
  irp: {
    main: '#84329b',
    dark: '#632789',
    light: '#9136a1',
    contrastText: '#ffffff',
  },
} as PaletteOptions;

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { styled } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import * as React from 'react';
import { COLOR_PALETTE } from '../../../../utils';
import { Order } from '../../../../utils/table-data-utils';

interface HeadCell<T> {
  id: keyof T;
  numeric: boolean;
  disablePadding: boolean;
  label: string;
}

interface EnhancedTableProps<T> {
  numSelected?: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof T) => void;
  onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  isWorkshopInEdit?: boolean;
  headCells: readonly HeadCell<T>[];
  isWorkshopChangeLog?: boolean;
}

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    color: COLOR_PALETTE.text.primary,
    fontSize: '14px',
    lineHeight: 1.71,
    letterSpacing: '0.17px',
    fontWeight: 'bold',
    fontStyle: 'normal',
  },
}));

const EnhancedTableHead = <T,>(
  props: EnhancedTableProps<T>
): React.ReactElement => {
  const {
    numSelected = 0,
    onRequestSort,
    onSelectAllClick,
    order,
    orderBy,
    rowCount,
    isWorkshopInEdit = false,
    headCells,
    isWorkshopChangeLog = false,
  } = props;

  const createSortHandler =
    (property: keyof T) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead data-testid="enhanced-table-head">
      <TableRow>
        {isWorkshopInEdit && (
          <StyledTableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all participants',
              }}
            />
          </StyledTableCell>
        )}
        <StyledTableCell padding="checkbox" />
        {isWorkshopChangeLog && (
          <StyledTableCell align="center" padding="checkbox" />
        )}
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id as string}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.id === 'time' ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              <span>{headCell.label}</span> // Render label without sorting functionality
            )}
          </StyledTableCell>
        ))}
        {isWorkshopInEdit && (
          <StyledTableCell align="center" padding="checkbox" />
        )}
      </TableRow>
    </TableHead>
  );
};

export default EnhancedTableHead;

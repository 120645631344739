import { createTheme } from '@mui/material';
import { TypographyOptions } from '@mui/material/styles/createTypography';
import { FONT_WEIGHT } from '../../../utils/base-styles';
import breakpoints from './breakpoints';

export const FONT_FAMILY = [
  'Circular Std',
  'Roboto',
  'Helvetica',
  'sans-serif',
].join(',');

const theme = createTheme({ breakpoints });

export default {
  h1: {
    fontFamily: FONT_FAMILY,
    color: '#222222',
    fontSize: '36px',
    lineHeight: '50px',
    letterSpacing: 0,
    fontWeight: FONT_WEIGHT.BOOK,
    [theme.breakpoints.down('sm')]: {
      fontSize: '30px',
      lineHeight: '38px',
    },
  },
  h2: {
    fontFamily: FONT_FAMILY,
    color: '#222222',
    fontSize: '30px',
    lineHeight: '44px',
    letterSpacing: 0,
    fontWeight: FONT_WEIGHT.BOOK,
    [theme.breakpoints.down('sm')]: {
      fontSize: '28px',
      lineHeight: '34px',
    },
  },
  h3: {
    fontFamily: FONT_FAMILY,
    color: '#222222',
    fontSize: '26px',
    lineHeight: '38px',
    letterSpacing: 0,
    fontWeight: FONT_WEIGHT.BOOK,
    [theme.breakpoints.down('sm')]: {
      fontSize: '26px',
      lineHeight: '32px',
    },
  },
  h4: {
    fontFamily: FONT_FAMILY,
    color: '#222222',
    fontSize: '22px',
    lineHeight: '32px',
    letterSpacing: 0,
    fontWeight: FONT_WEIGHT.BOLD,
    [theme.breakpoints.down('sm')]: {
      fontSize: '22px',
      lineHeight: '26px',
    },
  },
  h5: {
    fontFamily: FONT_FAMILY,
    color: '#222222',
    fontSize: '20px',
    lineHeight: '30px',
    letterSpacing: 0,
    fontWeight: FONT_WEIGHT.BOLD,
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
      lineHeight: '24px',
    },
  },
  h6: {
    fontFamily: FONT_FAMILY,
    color: '#222222',
    fontSize: '18px',
    lineHeight: '26px',
    fontWeight: FONT_WEIGHT.MEDIUM,
    textTransform: 'uppercase',
    letterSpacing: '1px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
      lineHeight: '22px',
    },
  },
  button: {
    fontFamily: FONT_FAMILY,
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: 0,
    fontWeight: FONT_WEIGHT.BOLD,
    color: '#FFFFFF',
    textTransform: 'none',
  },
  button1: {
    fontFamily: FONT_FAMILY,
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: 0,
    fontWeight: FONT_WEIGHT.BOLD,
    color: '#FFFFFF',
    textTransform: 'none',
  },
  subtitle1: {
    fontFamily: FONT_FAMILY,
    color: '#222222',
    fontSize: '18px',
    lineHeight: '22px',
    letterSpacing: '0.5px',
    fontWeight: FONT_WEIGHT.MEDIUM,
  },
  subtitle2: {
    fontFamily: FONT_FAMILY,
    color: '#222222',
    fontSize: '16px',
    lineHeight: '22px',
    letterSpacing: '0.5px',
    fontWeight: FONT_WEIGHT.MEDIUM,
  },
  body1: {
    fontFamily: FONT_FAMILY,
    color: '#222222',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: 0,
    fontWeight: FONT_WEIGHT.BOOK,
  },
  body2: {
    fontFamily: FONT_FAMILY,
    color: '#222222',
    fontSize: '18px',
    lineHeight: '24px',
    fontWeight: FONT_WEIGHT.MEDIUM,
    fontStyle: 'italic',
    letterSpacing: '1px',
  },
  body3: {
    fontFamily: FONT_FAMILY,
    color: '#222222',
    fontSize: '15px',
    lineHeight: '24px',
    fontWeight: FONT_WEIGHT.MEDIUM,
    letterSpacing: '0.5px',
  },
  body4: {
    fontFamily: FONT_FAMILY,
    color: '#222222',
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: FONT_WEIGHT.BOOK,
    letterSpacing: '1px',
  },
  body5: {
    fontFamily: FONT_FAMILY,
    color: '#222222',
    fontSize: '13px',
    lineHeight: '20px',
    fontWeight: FONT_WEIGHT.BOOK,
    letterSpacing: '1px',
  },
  caption1: {
    fontFamily: FONT_FAMILY,
    color: '#222222',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: FONT_WEIGHT.MEDIUM,
    letterSpacing: '1px',
  },
  overline1: {
    fontFamily: FONT_FAMILY,
    color: '#222222',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: FONT_WEIGHT.MEDIUM,
    textTransform: 'uppercase',
    letterSpacing: '1px',
  },
  overline2: {
    fontFamily: FONT_FAMILY,
    fontSize: '13px',
    lineHeight: '20px',
    fontWeight: FONT_WEIGHT.MEDIUM,
    color: '#636363',
    textTransform: 'uppercase',
    letterSpacing: '1px',
  },
  link1: {
    fontFamily: FONT_FAMILY,
    fontSize: '16px',
    lineHeight: '26px',
    fontWeight: FONT_WEIGHT.MEDIUM,
    color: '#1976D2',
    letterSpacing: 0,
  },
  link2: {
    fontFamily: FONT_FAMILY,
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: FONT_WEIGHT.MEDIUM,
    color: '#1976D2',
    letterSpacing: '1px',
  },
  link3: {
    fontFamily: FONT_FAMILY,
    fontSize: '12px',
    lineHeight: '20px',
    fontWeight: FONT_WEIGHT.MEDIUM,
    color: '#1976D2',
    textTransform: 'uppercase',
    letterSpacing: '1px',
  },
} as TypographyOptions;

import {
  Box,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
} from '@mui/material';
import Collapse from '@mui/material/Collapse';
import React from 'react';
import { COLOR } from '../../../../utils';

const commonHeadStyles = {
  fontSize: '16px',
  fontWeight: 'bold',
  fontStretch: 'normal',
  fontStyle: 'normal',
  letterSpacing: 'normal',
  borderBottom: 'none',
  minWidth: '200px',
  maxWidth: '200px',
};

const commonBodyStyles = {
  ...commonHeadStyles,
  fontWeight: 'normal',
};

interface ChangeLogRow {
  id: number;
  previous: string;
  current: string;
}

interface ChangeLogCollapseTableProps {
  row: ChangeLogRow;
  open: boolean;
}

const ChangeLogCollapseTable: React.FC<ChangeLogCollapseTableProps> = ({
  row,
  open,
}) => (
  <TableRow
    sx={{ backgroundColor: COLOR.grey[50] }}
    data-testid="change-log-collapse-table"
  >
    <TableCell style={{ paddingBottom: '0px', paddingTop: '0px' }} colSpan={8}>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box
          sx={{
            paddingLeft: '80px',
            paddingTop: '16px',
            paddingBottom: '16px',
          }}
        >
          <Table size="small" aria-label="change log table">
            <TableHead>
              <TableRow>
                <TableCell
                  align="left"
                  style={{ ...commonHeadStyles, paddingBottom: '0px' }}
                >
                  Previous:
                </TableCell>
                <TableCell
                  align="left"
                  style={{ ...commonHeadStyles, paddingBottom: '0px' }}
                >
                  Current:
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow key={row.id}>
                <TableCell align="left" style={commonBodyStyles}>
                  {row.previous}
                </TableCell>
                <TableCell align="left" style={commonBodyStyles}>
                  {row.current}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </Collapse>
    </TableCell>
  </TableRow>
);

export default ChangeLogCollapseTable;

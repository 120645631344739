import DoNotDisturbOnRoundedIcon from '@mui/icons-material/DoNotDisturbOnRounded';
import PauseCircleFilledRoundedIcon from '@mui/icons-material/PauseCircleFilledRounded';
import { Tooltip } from '@mui/material';
import Chip from '@mui/material/Chip';
import React from 'react';
import { COLOR, COLOR_PALETTE } from '../../utils';

enum ActivityStatus {
  ON_TRACK = 'On Track',
  OFF_TRACK = 'Off Track',
  TEMPORARILY_INACTIVE = 'Temporarily Inactive',
}

type StatusColor = {
  bgColor: string;
  textColor: string;
};

export const workshopStatusColors: Record<string, StatusColor> = {
  Default: {
    bgColor: COLOR.blue['50'],
    textColor: COLOR_PALETTE.text.primary,
  },
  Draft: {
    bgColor: COLOR.blue['50'],
    textColor: COLOR_PALETTE.text.primary,
  },
  'Submitted for Approval': {
    bgColor: COLOR.flexibleInstructionalModels['100'],
    textColor: COLOR_PALETTE.text.primary,
  },
  Rejected: {
    bgColor: COLOR.curriculumAndAcademicContent['50'],
    textColor: COLOR_PALETTE.text.primary,
  },
  'Ready to Staff': {
    bgColor: COLOR.springGreen['50'],
    textColor: COLOR_PALETTE.text.primary,
  },
  Confirmed: {
    bgColor: COLOR.oceanTeal['50'],
    textColor: COLOR_PALETTE.text.primary,
  },
  'In Progress': {
    bgColor: COLOR.inclusiveAndResponsivePractices['50'],
    textColor: COLOR_PALETTE.text.primary,
  },
  Completed: {
    bgColor: COLOR.springGreen['800'],
    textColor: COLOR_PALETTE.primary.contrastText,
  },
  Cancelled: {
    bgColor: COLOR.curriculumAndAcademicContent['600'],
    textColor: COLOR_PALETTE.primary.contrastText,
  },
};

export const sessionStatusColors: Record<string, StatusColor> = {
  Default: {
    bgColor: COLOR.blue['50'],
    textColor: COLOR_PALETTE.text.primary,
  },
  Draft: {
    bgColor: COLOR.blue['50'],
    textColor: COLOR_PALETTE.text.primary,
  },
  'Ready to Staff': {
    bgColor: COLOR.springGreen['50'],
    textColor: COLOR_PALETTE.text.primary,
  },
  Confirmed: {
    bgColor: COLOR.oceanTeal['50'],
    textColor: COLOR_PALETTE.text.primary,
  },
  Delivered: {
    bgColor: COLOR.springGreen['800'],
    textColor: COLOR_PALETTE.primary.contrastText,
  },
  Cancelled: {
    bgColor: COLOR.curriculumAndAcademicContent['600'],
    textColor: COLOR_PALETTE.primary.contrastText,
  },
};

export const cohortStatusColors: Record<string, StatusColor> = {
  Default: {
    bgColor: COLOR.blue['50'],
    textColor: COLOR_PALETTE.text.primary,
  },
  Draft: {
    bgColor: COLOR.blue['50'],
    textColor: COLOR_PALETTE.text.primary,
  },
  'Submitted for Approval': {
    bgColor: COLOR.flexibleInstructionalModels['100'],
    textColor: COLOR_PALETTE.text.primary,
  },
  Rejected: {
    bgColor: COLOR.curriculumAndAcademicContent['50'],
    textColor: COLOR_PALETTE.text.primary,
  },
  Approved: {
    bgColor: COLOR.springGreen['50'],
    textColor: COLOR_PALETTE.text.primary,
  },
  'In Progress': {
    bgColor: COLOR.inclusiveAndResponsivePractices['50'],
    textColor: COLOR_PALETTE.text.primary,
  },
  Completed: {
    bgColor: COLOR.springGreen['800'],
    textColor: COLOR_PALETTE.primary.contrastText,
  },
};

export const engagementStatusColors: Record<string, StatusColor> = {
  Default: {
    bgColor: COLOR.blue['50'],
    textColor: COLOR_PALETTE.text.primary,
  },
  Uploaded: {
    bgColor: COLOR.blue['50'],
    textColor: COLOR_PALETTE.text.primary,
  },
  Registered: {
    bgColor: COLOR.oceanTeal['50'],
    textColor: COLOR_PALETTE.text.primary,
  },
  Matched: {
    bgColor: COLOR.springGreen['50'],
    textColor: COLOR_PALETTE.text.primary,
  },
  Done: {
    bgColor: COLOR.springGreen['800'],
    textColor: COLOR_PALETTE.primary.contrastText,
  },
  Dropped: {
    bgColor: COLOR.grey['A400'],
    textColor: COLOR_PALETTE.primary.contrastText,
  },
  'In Progress': {
    bgColor: COLOR.inclusiveAndResponsivePractices['50'],
    textColor: COLOR_PALETTE.text.primary,
  },
};

export const defaultStatusColors: Record<string, StatusColor> = {
  Default: {
    bgColor: COLOR.blue['50'],
    textColor: COLOR_PALETTE.text.primary,
  },
};

const getStatusColorConfig = (scope: string) => {
  switch (scope) {
    case 'workshop':
      return workshopStatusColors;
    case 'session':
      return sessionStatusColors;
    case 'cohort':
      return cohortStatusColors;
    case 'engagement':
      return engagementStatusColors;
    default:
      return defaultStatusColors;
  }
};

const getStatusBgColor = (status: string | undefined, scope: string) => {
  const statusColors = getStatusColorConfig(scope);
  if (status !== undefined && status in statusColors) {
    return statusColors[status].bgColor;
  }
  return defaultStatusColors.Default.bgColor;
};

const getStatusTextColor = (status: string | undefined, scope: string) => {
  const statusColors = getStatusColorConfig(scope);
  if (status !== undefined && status in statusColors) {
    return statusColors[status].textColor;
  }
  return defaultStatusColors.Default.textColor;
};

interface StatusChipProps {
  status: string | undefined;
  activityStatus?: string | undefined;
  showActivity?: boolean;
  scope: string;
}

const chipStyle = {
  fontWeight: 'bold',
  fontFamily: 'Circular Std',
  fontSize: '13px',
  lineHeight: '18px',
  letterSpacing: '0.16px',
};

export const StatusChip: React.FC<StatusChipProps> = ({
  status,
  activityStatus,
  showActivity = false,
  scope,
}): React.JSX.Element => {
  const getIcon = (): React.ReactElement | undefined => {
    if (!showActivity) return undefined;

    let icon;
    let color;

    switch (activityStatus) {
      case ActivityStatus.OFF_TRACK:
        icon = DoNotDisturbOnRoundedIcon;
        color = COLOR_PALETTE.error.dark;
        break;
      case ActivityStatus.TEMPORARILY_INACTIVE:
        icon = PauseCircleFilledRoundedIcon;
        color = COLOR_PALETTE.warning.dark;
        break;
      default:
        return undefined;
    }

    return (
      <Tooltip
        title={
          <div
            style={{
              whiteSpace: 'pre-line',
              color: COLOR_PALETTE.text.primary,
              fontFamily: 'Circular Std',
              fontSize: '14px',
              lineHeight: '24px',
            }}
          >
            {activityStatus}
          </div>
        }
        placement="right"
        arrow
        componentsProps={{
          tooltip: {
            sx: {
              bgcolor: COLOR_PALETTE.other.snackbarBackground,
              borderRadius: '8px',
              '& .MuiTooltip-arrow': {
                color: COLOR_PALETTE.other.snackbarBackground,
              },
            },
          },
        }}
        data-testid="status-chip-activity-status-tooltip"
      >
        {React.createElement(icon, { style: { color } })}
      </Tooltip>
    );
  };

  if (showActivity) {
    return (
      <Chip
        label={status}
        deleteIcon={getIcon()}
        onDelete={() => undefined}
        style={{
          backgroundColor: getStatusBgColor(status, scope),
          color: getStatusTextColor(status, scope),
          ...chipStyle,
        }}
      />
    );
  } else {
    return (
      <Chip
        label={status}
        style={{
          backgroundColor: getStatusBgColor(status, scope),
          color: getStatusTextColor(status, scope),
          ...chipStyle,
        }}
      />
    );
  }
};

import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
} from '@mui/material';

type ButtonType = {
  defaultProps?: ComponentsProps['MuiButton'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiButton'];
  variants?: ComponentsVariants['MuiButton'];
};

export default {
  styleOverrides: {
    root: {
      borderRadius: 8,
      fontSize: '15px',
      lineHeight: '24px',
      letterSpacing: '0.32px',
    },
    sizeSmall: {
      lineHeight: '22px',
      letterSpacing: '0.43px',
    },
    sizeLarge: {
      fontSize: '16px',
      lineHeight: '20px',
      letterSpacing: 'normal',
    },
    outlined: {
      borderWidth: '0.125rem',

      '&:hover': {
        borderWidth: '0.125rem',
      },

      '&:disabled': {
        borderWidth: '0.125rem',
      },
    },
  },
} as ButtonType;

import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
} from '@mui/material';
import { FONT_FAMILY } from '../typography';

type FormHelperText = {
  defaultProps?: ComponentsProps['MuiFormHelperText'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiFormHelperText'];
  variants?: ComponentsVariants['MuiFormHelperText'];
};

export default {
  styleOverrides: {
    root: {
      fontFamily: FONT_FAMILY,
    },
  },
} as FormHelperText;

import { COLOR_PALETTE } from './base-styles';

const descendingComparator = <T>(a: T, b: T, orderBy: keyof T): number => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

export type Order = 'asc' | 'desc';

export const getComparator = <Key extends string | number | symbol>(
  order: 'asc' | 'desc',
  orderBy: Key
): ((
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

export const capitalizeFirstLetter = (string: string): string => {
  if (!string || string === 'N/A') {
    return 'N/A';
  }
  return string
    .toLowerCase()
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const tableCellStyle = {
  color: COLOR_PALETTE.text.primary,
  fontSize: '16px',
  lineHeight: 1.5,
  letterSpacing: 'normal',
  fontWeight: '300',
  fontStyle: 'normal',
};

export const tablePaginationStyle = {
  '.MuiTablePagination-toolbar': {
    '.MuiTablePagination-selectLabel': {
      color: COLOR_PALETTE.text.secondary,
      fontSize: '14px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.43,
      letterSpacing: '1px',
    },
    '.MuiInputBase-input': {
      color: COLOR_PALETTE.text.primary,
      fontSize: '14px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.43,
      letterSpacing: '1px',
    },
    '.MuiTablePagination-displayedRows': {
      color: COLOR_PALETTE.text.primary,
      fontSize: '14px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.43,
      letterSpacing: '1px',
    },
  },
};

import { FONT_WEIGHT } from '../../utils';

const color = 'var(--color-grey-2)';

export const ChildButtonStyle = {
  '&.MuiButtonBase-root:hover': {
    bgcolor: 'transparent',
  },
  color,
  paddingLeft: '1.5rem',
  paddingRight: '1.5rem',
  justifyContent: 'flex-start',
  fontSize: '14px',
  fontWeight: FONT_WEIGHT.MEDIUM,
};

export const MenuButtonStyle = {
  borderRadius: 0,
  borderBottom: '0.125rem solid transparent',
  color,
  '&:hover': {
    backgroundColor: 'inherit',
    borderBottom: '.125rem solid var(--color-spring-green)',
  },
  fontFamily: 'Circular Std',
  fontWeight: FONT_WEIGHT.BOLD,
  fontSize: '14px',
};

export const MenuStyle = {
  borderStyle: 'solid',
  borderWidth: '0.5rem 0 0 0',
  borderImage:
    'linear-gradient(to bottom, var(--color-spring-green), var(--color-green-dark)) 1',
  borderImageSlice: 1,
};

export const PaperStyle = {
  mr: '0.75rem',
  ':last-child': {
    mx: 0,
  },

  ':nth-last-child(2)': {
    marginLeft: 'auto',
  },
};

import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
} from '@mui/material';

type AppBarType = {
  defaultProps?: ComponentsProps['MuiAppBar'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiAppBar'];
  variants?: ComponentsVariants['MuiAppBar'];
};

export default {
  styleOverrides: {
    root: {
      backgroundColor: '#fff',
    },
  },
} as AppBarType;

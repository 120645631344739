import { Close } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Button,
  List,
  ListItem,
  Stack,
  Typography,
} from '@mui/material';
import { User } from 'oidc-client-ts';
import BetterLessonLabLogo from '../../../../images/logos/betterlesson-lab-logo.svg';
import { FONT_WEIGHT } from '../../../../utils';
import { getUserIconColor } from '../../../../utils/base-styles';
import { ItemLinkType } from '../../nav-links/types';
import { USER } from '../../nav-links/user';
import { Tab, NavLink } from '../../typeDefinition';

type Props = {
  initials: string;
  logoLink: string;
  handleSignOut: () => void;
  tabsData: Tab[];
  toggleDrawer: () => void;
  user: User | null | undefined;
};

export const MobileNav = ({
  initials,
  logoLink,
  handleSignOut,
  tabsData,
  toggleDrawer,
  user,
}: Props): JSX.Element => {
  const renderCloseButton = () => (
    <Button onClick={toggleDrawer} variant="text">
      <Close style={{ fill: 'var(--color-grey-2)' }} />
    </Button>
  );

  const renderLogo = () => (
    <Stack
      display="flex"
      direction="row"
      marginBottom=".5rem"
      marginTop=".5rem"
      py={2}
      pl={2}
      justifyContent="space-between"
      width="100%"
    >
      <a href={logoLink}>
        <Avatar
          alt="BetterLesson"
          src={BetterLessonLabLogo}
          style={{ height: 'auto', width: '50%' }}
          variant="square"
        />
      </a>
      {renderCloseButton()}
    </Stack>
  );

  const renderAccordionDetails = (navLinks: NavLink[]) => (
    <AccordionDetails>
      <List>
        {navLinks.map((item) => (
          <ListItem key={item.name}>
            <Button
              href={item.url}
              style={{
                fontSize: '14px',
                fontWeight: FONT_WEIGHT.MEDIUM,
                color: 'var(--color-grey-2)',
              }}
              onClick={item.name === 'Log Out' ? handleSignOut : undefined}
            >
              {item.name}
            </Button>
          </ListItem>
        ))}
      </List>
    </AccordionDetails>
  );

  const renderAccordion = (tab: Tab) => (
    <Accordion key={tab.name} data-testid="menu-accordion" sx={{ px: 2 }}>
      <AccordionSummary>
        <Typography fontWeight="bold">{tab.name}</Typography>
      </AccordionSummary>

      {renderAccordionDetails(tab.navLinks)}
    </Accordion>
  );

  const renderUserAccordionDetails = (menuItems: ItemLinkType[]) => (
    <AccordionDetails>
      <List>
        {menuItems.map((item) => (
          <ListItem key={item.name}>
            <Button
              href={item.href}
              style={{
                fontSize: '14px',
                fontWeight: FONT_WEIGHT.MEDIUM,
                color: 'var(--color-grey-2)',
              }}
              target={item.target ? item.target : '_self'}
              onClick={item.name === 'Log Out' ? handleSignOut : undefined}
            >
              {item.name}
            </Button>
          </ListItem>
        ))}
      </List>
    </AccordionDetails>
  );

  const renderUserAccordion = (menuName: string, menuItems: ItemLinkType[]) => (
    <Accordion defaultExpanded key={menuName}>
      <AccordionSummary>
        <Stack direction="row">
          <Avatar
            alt="User"
            style={{ backgroundColor: getUserIconColor(initials) }}
            sx={{ fontSize: '14px', fontWeight: FONT_WEIGHT.MEDIUM }}
          >
            {initials}
          </Avatar>

          <Typography fontWeight="700" marginLeft="1rem">
            {user?.profile.given_name} {user?.profile.family_name}
          </Typography>
        </Stack>
      </AccordionSummary>

      {renderUserAccordionDetails(menuItems)}
    </Accordion>
  );

  return (
    <Stack data-testid="mobile-nav">
      {renderLogo()}

      {tabsData.map(renderAccordion)}

      {renderUserAccordion(USER.menuName, USER.menuItems)}
    </Stack>
  );
};

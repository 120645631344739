import { Search } from '@mui/icons-material';
import {
  Avatar,
  Button,
  Menu,
  MenuItem,
  MenuProps,
  Paper,
  Stack,
} from '@mui/material';
import React, { useState, MouseEvent } from 'react';
import {
  ChildButtonStyle,
  MenuButtonStyle,
  MenuStyle,
  PaperStyle,
  FONT_WEIGHT,
  getUserIconColor,
} from '../../Styles';
import BetterLessonLabLogo from '../../betterlesson-lab-logo.svg';
import { NAV_NAMES } from '../../constants/nav-names';
import { NAV_LINKS } from '../../nav-links';
import { NavLinkType } from '../../nav-links/types';
import { Tab } from '../../typeDefinition';

type Props = {
  initials: string;
  logoLink: string;
  handleSignOut: () => void;
  tabsData: Tab[];
};

export const DesktopNav = ({
  initials,
  logoLink,
  handleSignOut,
  tabsData,
}: Props): React.ReactElement => {
  const [anchorEl, setAnchorEl] = useState<Record<number, HTMLElement | null>>(
    {}
  );

  const handleClose = (i: number) => {
    setAnchorEl((prevAnchorEl) => ({
      ...prevAnchorEl,
      [i]: null,
    }));
  };

  const getBaseMenuProps = (index: number): MenuProps => ({
    anchorEl: anchorEl[index],
    id: 'unified-nav-header-v2-menu',
    open: Boolean(anchorEl[index]),
    onClose: () => handleClose(index),
    sx: {
      marginTop: '.625rem',
      ul: MenuStyle,
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
  });

  const handleClick = (e: MouseEvent<HTMLButtonElement>, i: number) => {
    setAnchorEl((prevAnchorEl) => ({
      ...prevAnchorEl,
      [i]: e.target as HTMLElement,
    }));
  };

  const formatMenuName = (menuName: string) =>
    menuName.replace(/\s+/g, '-').toLowerCase();

  const betterLessonLogo = (
    <a href={logoLink}>
      <img
        alt="BetterLesson"
        data-testid="unified-nav-header-v2-menu-logo"
        src={BetterLessonLabLogo}
        style={{
          height: '2.5rem',
        }}
      />
    </a>
  );

  const searchStrategiesAndLessons = (
    <Button
      data-testid="unified-nav-header-v2-menu-search-strategies-and-lessons"
      startIcon={<Search style={{ fontSize: '1.5rem' }} />}
      variant="text"
      sx={{ fontSize: '14px', fontWeight: FONT_WEIGHT.MEDIUM }}
    >
      <a
        href="//teaching.betterlesson.com/search/1968156"
        target="_blank"
        rel="noopener noreferrer"
        style={{
          color: 'var(--color-vivid-blue)',
          textDecoration: 'none',
        }}
      >
        Strategies & Lessons
      </a>
    </Button>
  );

  const userAvatar = (link: NavLinkType) => (
    <>
      <Avatar
        alt={NAV_NAMES.USER}
        style={{ backgroundColor: getUserIconColor(initials) }}
      >
        <Button
          color="inherit"
          data-testid={'unified-nav-header-v2-avatar-button'}
          sx={{
            fontSize: '14px',
            fontWeight: FONT_WEIGHT.MEDIUM,
          }}
          disableRipple
          onClick={(e) => handleClick(e, tabsData.length)}
        >
          {initials}
        </Button>

        <Menu
          {...getBaseMenuProps(tabsData.length)}
          MenuListProps={{
            'aria-labelledby': 'unified-nav-header-v2-menu',
          }}
        >
          {link.menuItems.map((item) => (
            <MenuItem
              data-testid={`unified-nav-header-v2-menu-item-${formatMenuName(
                item.name
              )}`}
              key={item.name}
              style={{ paddingLeft: 0, paddingRight: 0 }}
              tabIndex={0}
            >
              {item.name === NAV_NAMES.LOG_OUT ? (
                <Button
                  data-testid="unified-nav-header-v2-logout-button"
                  disableRipple
                  fullWidth
                  onClick={handleSignOut}
                  sx={ChildButtonStyle}
                  variant="text"
                >
                  {item.name}
                </Button>
              ) : (
                <Button
                  disableRipple
                  fullWidth
                  href={item.href}
                  sx={ChildButtonStyle}
                  variant="text"
                >
                  {item.name}
                </Button>
              )}
            </MenuItem>
          ))}
        </Menu>
      </Avatar>
    </>
  );

  const menuTabsAndLinks = tabsData.map((tab, index) => (
    <>
      <Button
        data-testid={`unified-nav-header-v2-menu-${formatMenuName(tab.name)}`}
        disableRipple
        sx={MenuButtonStyle}
        onClick={(e) => handleClick(e, index)}
      >
        {tab.name}
      </Button>

      <Menu
        {...getBaseMenuProps(index)}
        MenuListProps={{
          'aria-labelledby': 'unified-nav-header-v2-menu-button',
        }}
      >
        {tab.navLinks.map((link) => (
          <MenuItem
            data-testid={`unified-nav-header-v2-menu-item-${formatMenuName(
              link.name
            )}`}
            key={link.name}
            style={{ padding: 0 }}
            tabIndex={0}
          >
            <Button
              disableRipple
              fullWidth
              href={link.url}
              sx={ChildButtonStyle}
              variant="text"
            >
              {link.name}
            </Button>
          </MenuItem>
        ))}
      </Menu>
    </>
  ));

  return (
    <Stack
      data-testid="desktop-unified-nav-header-v2"
      display="flex"
      direction="row"
      width="100%"
      px={3}
    >
      <Paper elevation={0} sx={PaperStyle}>
        {betterLessonLogo}
      </Paper>
      <Paper elevation={0} sx={PaperStyle}>
        {menuTabsAndLinks}
      </Paper>
      <Paper elevation={0} sx={PaperStyle}>
        {searchStrategiesAndLessons}
      </Paper>
      <Paper elevation={0} sx={PaperStyle}>
        {userAvatar(
          NAV_LINKS.find(
            (link) => link.menuName === NAV_NAMES.USER
          ) as NavLinkType
        )}
      </Paper>
    </Stack>
  );
};

import { ArrowCircleRightRounded } from '@mui/icons-material';
import { Typography, Box, Link } from '@mui/material';

export const TEST_ID = {
  HEADER: 'contact-support-header',
  DESCRIPTION: 'contact-support-description',
  LINK: 'contact-support-link',
};

export type Props = {
  header?: string;
  description?: string;
};

export function ContactSupport({
  header = 'Need help?',
  description = "We're available Mon-Fri, 8 AM-5 PM Eastern Time (ET).",
}: Props): JSX.Element {
  return (
    <>
      <Typography data-testid={TEST_ID.HEADER} tabIndex={0} variant="subtitle1">
        {header}
      </Typography>

      <Typography data-testid={TEST_ID.DESCRIPTION} tabIndex={0}>
        {description}
      </Typography>

      <Link
        data-testid={TEST_ID.LINK}
        href="https://betterlesson.atlassian.net/servicedesk/customer/portal/2/group/4"
        target="_blank"
        underline="none"
      >
        <Box alignItems="center" display="flex">
          <Typography mr=".25rem" variant="link1">
            Contact support
          </Typography>
          <ArrowCircleRightRounded />
        </Box>
      </Link>
    </>
  );
}

import CheckCircleOutlineRounded from '@mui/icons-material/CheckCircleOutlineRounded';
import ErrorOutlineRounded from '@mui/icons-material/ErrorOutlineRounded';
import WarningAmberRounded from '@mui/icons-material/WarningAmberRounded';
import { Alert, AlertColor, Typography } from '@mui/material';
import { ToastContentProps } from 'react-toastify';
import { COLOR_PALETTE } from '../../utils';

type Data = {
  message: string;
};

export type Props = ToastContentProps<Data>;
export const TEST_ID = { CONTAINER: 'toast' };

export function Toast({ toastProps, data }: ToastContentProps): JSX.Element {
  const getStyles = () => {
    switch (toastProps.type) {
      case 'success':
        return {
          icon: (
            <CheckCircleOutlineRounded
              fontSize="inherit"
              sx={{ fill: COLOR_PALETTE.success.alertText }}
            />
          ),
          color: COLOR_PALETTE.success.alertText,
        };
      case 'error':
        return {
          icon: (
            <ErrorOutlineRounded
              fontSize="inherit"
              sx={{ fill: COLOR_PALETTE.error.alertText }}
            />
          ),
          color: COLOR_PALETTE.error.alertText,
        };
      case 'warning':
        return {
          icon: (
            <WarningAmberRounded
              fontSize="inherit"
              sx={{ fill: COLOR_PALETTE.warning.alertText }}
            />
          ),
          color: COLOR_PALETTE.warning.alertText,
        };
      case 'info':
        return {
          icon: (
            <ErrorOutlineRounded
              fontSize="inherit"
              sx={{
                fill: COLOR_PALETTE.info.alertText,
                transform: 'rotate(180deg)',
              }}
            />
          ),
          color: COLOR_PALETTE.info.alertText,
        };

      default:
        throw Error('Toast is called with unsupported type');
    }
  };

  const styles = getStyles();

  return (
    <Alert
      data-testid={TEST_ID.CONTAINER}
      id={String(toastProps.toastId)}
      key={toastProps.key}
      sx={{ display: 'flex', alignItems: 'center' }}
      icon={styles.icon}
      severity={toastProps.type as AlertColor}
    >
      <Typography fontStyle="normal" color={styles.color} fontSize={14}>
        {(data as unknown as Data)?.message}
      </Typography>
    </Alert>
  );
}

import { COLOR_PALETTE } from './base-styles';

interface TabPropWithAriaControlProps {
  id: string;
  'aria-controls': string;
}

export const serviceTabPropWithAriaControl = (
  index: number
): TabPropWithAriaControlProps => ({
  id: `vertical-tab-${index}`,
  'aria-controls': `vertical-tabpanel-${index}`,
});

export const serviceTabStyle = {
  borderRight: 1,
  borderColor: 'divider',
  bgcolor: COLOR_PALETTE.action.hover,
  '& .MuiTab-root': {
    minWidth: '200px',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingRight: '1em',
  },
  '& .MuiSvgIcon-root': {
    marginRight: '4px',
    marginBottom: '0px !important',
    marginLeft: '0.75em',
  },
  '&:first-of-type': {
    paddingTop: '2em',
  },
  '& .session-sub-tab': {
    marginLeft: '3.5em',
    fontSize: '0.85em',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
};

import { Menu as MenuIcon } from '@mui/icons-material';
import {
  AppBar,
  Avatar,
  Button,
  Drawer,
  Stack,
  Toolbar,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import BetterLessonLabLogo from '../../images/logos/betterlesson-lab-logo.svg';
import { DesktopNav } from './components/DesktopNav/DesktopNav';
import { MobileNav } from './components/MobileNav/MobileNav';
import { RESOURCES } from './nav-links/resources';
import { NavLinkV2DataType, Tab } from './typeDefinition';

type Props = {
  navData: NavLinkV2DataType;
  featureFlagsTurnedOn?: boolean;
};

export const UnifiedHeaderV2 = ({
  navData,
  featureFlagsTurnedOn,
}: Props): React.ReactElement => {
  const { user, signoutRedirect } = useAuth();
  const [mobileIsOpen, setMobileIsOpen] = useState(false);
  const theme = useTheme();
  const isTabletOrLarger = useMediaQuery(theme.breakpoints.up('md'));

  const [logoUrl, setLogoUrl] = useState<string>(
    'https://www.betterlesson.com'
  );
  const [TabsData, setTabsData] = useState<Tab[]>([]);

  const handleToggle = () => setMobileIsOpen(!mobileIsOpen);

  const resourcesLinks = RESOURCES.menuItems.map((item) => ({
    name: item.name,
    url: item.href,
    checkFeatureFlag: false,
    hideOnFeatureFlag: false,
  }));

  useEffect(() => {
    if (navData.logoUrl) setLogoUrl(navData.logoUrl);
    if (navData.tabs) {
      const newTabsData = navData.tabs.map((tab) => {
        return {
          ...tab,
          navLinks: tab.navLinks.filter(
            (link) => !(featureFlagsTurnedOn && link.hideOnFeatureFlag)
          ),
        };
      });

      newTabsData.push({
        name: RESOURCES.menuName,
        navLinks: resourcesLinks,
      });

      setTabsData(newTabsData);
    } else {
      setTabsData([
        {
          name: RESOURCES.menuName,
          navLinks: resourcesLinks,
        },
      ]);
    }
  }, [navData]);

  const handleSignOut = () => {
    void signoutRedirect();
  };

  const getInitials = (): string => {
    if (!user || !user.profile.given_name) return '';
    return (
      (
        user.profile.given_name?.charAt(0) + user.profile.family_name?.charAt(0)
      ).toUpperCase() || ''
    );
  };

  const desktopView = (
    <DesktopNav
      initials={getInitials()}
      logoLink={logoUrl}
      handleSignOut={handleSignOut}
      tabsData={TabsData}
    />
  );

  return (
    <AppBar elevation={3} position="relative">
      <Toolbar>
        {isTabletOrLarger ? (
          desktopView
        ) : (
          <>
            <Stack
              data-testid="mobile-nav-container"
              direction="row"
              justifyContent="space-between"
              width="100%"
            >
              <a href={logoUrl}>
                <Avatar
                  alt="BetterLesson"
                  src={BetterLessonLabLogo}
                  style={{ height: 'auto', width: '50%' }}
                  variant="square"
                />
              </a>

              <Button
                data-testid="btn-toggle"
                onClick={handleToggle}
                style={{ justifyContent: 'end' }}
              >
                <MenuIcon style={{ fill: 'var(--color-grey-2)' }} />
              </Button>
            </Stack>

            <Drawer
              anchor="top"
              open={mobileIsOpen}
              PaperProps={{
                style: {
                  width: '100%',
                },
              }}
            >
              <MobileNav
                initials={getInitials()}
                logoLink={logoUrl}
                handleSignOut={handleSignOut}
                toggleDrawer={handleToggle}
                tabsData={TabsData}
                user={user}
              />
            </Drawer>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

import { Container, Grid, Link, Typography } from '@mui/material';
import { useEffect } from 'react';
import UnauthorizedImg from '../../images/unauthorized.png';

export const Unauthorized = (): JSX.Element => {
  useEffect(() => {
    setTimeout(() => {
      window.location.assign(`${process.env.REACT_APP_USER_MANAGEMENT_URL}`);
    }, 10000);
  }, []);

  return (
    <Container sx={{ mt: 7 }} data-testid="unauthorized">
      <Grid container px={5}>
        <Grid item xs={12} md={7} mt={7}>
          <img
            src={UnauthorizedImg}
            alt="Unauthorized"
            style={{ width: '100%' }}
          />
        </Grid>

        <Grid item xs={12} md={5} my={14}>
          <span>
            <Typography pb={5} variant="h5">
              Ni! None Shall Pass!
            </Typography>
            <Typography variant="body1">
              We&rsquo;re sorry, but you don&rsquo;t have access to this page.
              Try visiting your{' '}
              <Link
                href={`${process.env.REACT_APP_USER_MANAGEMENT_URL}`}
                underline="none"
              >
                home page
              </Link>
              , our{' '}
              <Link
                href={`${process.env.REACT_APP_TEACHING_URL}/search`}
                underline="none"
              >
                strategies & lesson plans
              </Link>
              , or our{' '}
              <Link href="https://blog.betterlesson.com" underline="none">
                blog
              </Link>
              . If you think you&rsquo;re seeing this message in error, please{' '}
              <Link
                href="https://betterlesson.atlassian.net/servicedesk/customer/portal/2/group/4"
                underline="none"
              >
                contact us
              </Link>
              .
            </Typography>
          </span>
        </Grid>
      </Grid>
    </Container>
  );
};

import { Stack, CircularProgress } from '@mui/material';

type Props = {
  'data-testid': string;
};

export function LoadingPage(props: Props): JSX.Element {
  return (
    <Stack
      {...props}
      alignItems="center"
      justifyContent="center"
      height="100vh"
    >
      <CircularProgress size={80} />
    </Stack>
  );
}

import {
  Components,
  CssBaseline,
  Theme,
  ThemeProvider,
  createTheme,
} from '@mui/material';
import React, { ReactNode } from 'react';
import breakpoints from './theme/breakpoints';
import AppBar from './theme/components/AppBar';
import Button from './theme/components/Button';
import Checkbox from './theme/components/Checkbox';
import MuiCssBaseline from './theme/components/CssBaseline';
import FormHelperText from './theme/components/FormHelperText';
import MenuItem from './theme/components/MenuItem';
import Tooltip from './theme/components/Tooltip';
import palette from './theme/palette';
import typography from './theme/typography';

interface ThemeWrapperProps {
  children: ReactNode;
  spacing?: number;
  components?: Components<Omit<Theme, 'components'>>;
}

export const ThemeWrapper: React.FC<ThemeWrapperProps> = ({
  children,
  components = {},
  spacing = 4,
}) => {
  const theme = createTheme({
    breakpoints,
    typography,
    spacing,
    palette,
    components: {
      MuiButton: Button,
      MuiCheckbox: Checkbox,
      MuiFormHelperText: FormHelperText,
      MuiAppBar: AppBar,
      MuiMenuItem: MenuItem,
      MuiTooltip: Tooltip,
      MuiCssBaseline,
      ...components,
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

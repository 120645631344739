import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
} from '@mui/material';

type CheckBox = {
  defaultProps?: ComponentsProps['MuiCheckbox'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiCheckbox'];
  variants?: ComponentsVariants['MuiCheckbox'];
};

export default {
  styleOverrides: {
    root: {
      '&.Mui-disabled': {
        color: 'rgba(61, 61, 61, 0.61)',
      },
    },
  },
} as CheckBox;

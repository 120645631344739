import { Theme } from '@emotion/react';
import {
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants,
} from '@mui/material';
import { COLOR, COLOR_PALETTE } from '../../../../utils';

type TooltipType = {
  defaultProps?: ComponentsProps['MuiTooltip'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiTooltip'];
  variants?: ComponentsVariants['MuiTooltip'];
};

export default {
  styleOverrides: {
    tooltip: {
      font: 'normal normal bold 11px/14px "Circular Std"',
      backgroundColor: COLOR.lightBlue[100],
      color: COLOR_PALETTE.text.primary,

      '.MuiTooltip-arrow': {
        color: COLOR.lightBlue[100],
      },
    },
  },
} as TooltipType;

import * as Colors from '@mui/material/colors';

export const FONT_WEIGHT = Object.freeze({
  BOOK: 300,
  MEDIUM: 500,
  BOLD: 600,
  BLACK: 800,
});

export const BREAKPOINT = Object.freeze({
  MOBILE: 900,
  TABLET: 1200,
  DESKTOP: 1536,
});

export const COLOR = Object.freeze({
  indigo: Colors.indigo,
  blue: Colors.blue,
  lightBlue: Colors.lightBlue,
  blueGrey: Colors.blueGrey,
  grey: {
    ...Colors.grey,
    A100: '#d5d5d5',
    A200: '#aaaaaa',
    A400: '#616161',
    A700: '#303030',
  },
  oceanTeal: {
    50: '#e0f7f6',
    100: '#b2ebe9',
    200: '#82dedc',
    300: '#51d0d0',
    400: '#2ec6c8',
    500: '#08bcc3',
    600: '#0AABB1',
    700: '#0e9698',
    800: '#0e8282',
    900: '#0e5f59',
    A100: '#a2f0f2',
    A200: '#53E6EB',
    A400: '#00dae4',
    A700: '#00c6d9',
  },
  springGreen: {
    50: '#e7f6eb',
    100: '#c4e8ce',
    200: '#9fdaae',
    300: '#76cc8e',
    400: '#56c076',
    500: '#33B55F',
    600: '#2aa654',
    700: '#209348',
    800: '#15823e',
    900: '#00632a',
    A100: '#98f1de',
    A200: '#25e8c8',
    A400: '#00dab0',
    A700: '#00bf8d',
  },
  flexibleInstructionalModels: {
    50: '#fdf8e2',
    100: '#f9ebb6',
    200: '#f6df87',
    300: '#f4d459',
    400: '#f2c93a',
    500: '#f0c029',
    600: '#F0B323',
    700: '#efa11e',
    800: '#ef911a',
    900: '#ed7514',
    A100: '#FFE57F',
    A200: '#FFD740',
    A400: '#FFC400',
    A700: '#FFAB00',
  },
  instructionalLeadership: {
    50: '#ffebb2',
    100: '#ffd24c',
    200: '#ffc723',
    300: '#ffbd00',
    400: '#ffaf00',
    500: '#ff9c00',
    600: '#ff8b00',
    700: '#ff6900',
    800: '#E55300',
    900: '#D54500',
    A100: '#FFD17F',
    A200: '#FFBC40',
    A400: '#FFAA00',
    A700: '#FFAB00',
  },
  curriculumAndAcademicContent: {
    50: '#fde3e7',
    100: '#fbb9c4',
    200: '#f68b9e',
    300: '#f05d78',
    400: '#ea3b5c',
    500: '#e31c43',
    600: '#d31542',
    700: '#bf0d3e',
    800: '#ac013e',
    900: '#8a003a',
    A100: '#FF8080',
    A200: '#FF5252',
    A400: '#FF174E',
    A700: '#D50038',
  },
  inclusiveAndResponsivePractices: {
    50: '#f1e5f3',
    100: '#ddbfe3',
    200: '#c895d1',
    300: '#b26dbd',
    400: '#a14faf',
    500: '#9136a1',
    600: '#84329B',
    700: '#732c92',
    800: '#632789',
    900: '#471f78',
    A100: '#EB75FD',
    A200: '#D337F9',
    A400: '#C603ED',
    A700: '#A402F5',
  },
});

export const GRADIENT = Object.freeze({
  BLUE: 'linear-gradient(135deg, #0aabb1, #1a7cd9)',
  AGAVE: 'linear-gradient(135deg, #25e8c8, #26cba1)',
  GREEN: 'linear-gradient(135deg, #33b55f, #107d54)',
  TEAL: 'linear-gradient(135deg, #25e8c8, #2acaa1)',
  SLATE: 'linear-gradient(135deg, #eceff1 1%, #b0bec5)',
});

export const COLOR_PALETTE = Object.freeze({
  primary: {
    main: '#1976d2',
    dark: '#1565c0',
    light: '#42a5f5',
    contrastText: '#ffffff',
  },
  secondary: {
    main: '#08bcc3',
    dark: '#0e9698',
    light: '#51d0d0',
    contrastText: '#ffffff',
  },
  text: {
    primary: '#222222',
    secondary: '#636363',
    disabled: 'rgba(34, 34, 34, 0.61)',
  },
  textDark: {
    high: '#222222',
    medium: '#636363',
    low: 'rgba(34, 34, 34, 0.61)',
  },
  textLight: {
    high: '#ffffff',
    medium: 'rgba(255, 255, 255, 0.7)',
    low: 'rgba(255, 255, 255, 0.5)',
  },
  action: {
    active: 'rgba(61, 61, 61, 0.54)',
    hover: 'rgba(61, 61, 61, 0.04)',
    disabled: 'rgba(61, 61, 61, 0.26)',
    selected: '#e1f5fe',
    focus: 'rgba(61, 61, 61, 0.12)',
    disabledBackground: 'rgba(61, 61, 61, 0.12)',
  },
  error: {
    main: '#d31542',
    dark: '#ac013e',
    light: '#f05d78',
    contrastText: '#ffffff',
    alertText: '#8a003a',
  },
  warning: {
    main: '#ff8b00',
    dark: '#e55300',
    light: '#ffaa00',
    contrastText: '#ffffff',
    alertText: '#d54500',
  },
  info: {
    main: '#0288d1',
    dark: '#01579b',
    light: '#03a9f4',
    contrastText: '#ffffff',
    alertText: '#0d3c61',
  },
  success: {
    main: '#209348',
    dark: '#15823e',
    light: '#33b55f',
    contrastText: '#ffffff',
    alertText: '#00632a',
  },
  divider: 'rgba(0, 0, 0, 0.12)',
  other: {
    divider: 'rgba(0, 0, 0, 0.12)',
    backdropBackground: 'rgba(0, 0, 0, 0.5)',
    tooltipBackground: 'rgba(97, 97, 97, 0.9)',
    snackbarBackground: '#b3e5fc',
  },
  fim: {
    main: '#f0b323',
    dark: '#ef911a',
    light: '#f2c93a',
    contrastText: '#222222',
  },
  cac: {
    main: '#bf0d3e',
    dark: '#ac013e',
    light: '#e31c43',
    contrastText: '#ffffff',
  },
  il: {
    main: '#ff6900',
    dark: '#e55300',
    light: '#ff9c00',
    contrastText: '#ffffff',
  },
  irp: {
    main: '#84329b',
    dark: '#632789',
    light: '#9136a1',
    contrastText: '#ffffff',
  },
});

const COLORS = [
  '#004493',
  '#1A7CD9',
  '#2196F3',
  '#0B8484',
  '#0AABB1',
  '#53E6EB',
  '#95FFEE',
  '#107D54',
  '#33B55F',
  '#2ACAA1',
  '#25E8C8',
  '#FF6B2A',
  '#F4AB2A',
];

export const getUserIconColor = (initials: string): string => {
  const index =
    initials
      .split('')
      .map((letter) => letter.charCodeAt(0))
      .reduce((code, acc) => code + acc, 0) % COLORS.length;

  return COLORS[index];
};

import {
  Avatar,
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { CSSProperties } from 'react';
import BetterLessonLogo from '../../images/logos/betterlesson.svg';
import Facebook from '../../images/logos/social-media/facebook.png';
import LinkedIn from '../../images/logos/social-media/linkedin.png';
import Twitter from '../../images/logos/social-media/twitter.png';
import { FONT_WEIGHT } from '../../utils';
import Links, { FooterLink, TitleLink } from './Links';

const AvatarStyle: CSSProperties = { height: '2rem', width: '2rem' };

export const UnifiedFooter = (): JSX.Element => {
  const renderSocialMedia = () => {
    return (
      <Grid item>
        <Typography
          color="var(--color-grey-v2-1)"
          data-testid="unified-footer-link"
          fontFamily="Circular Std"
          fontWeight={FONT_WEIGHT.BOLD}
          fontSize=".875rem"
          marginTop="1rem"
          tabIndex={0}
        >
          Connect With Us
        </Typography>

        <List component={Stack} direction="row">
          <ListItem disablePadding>
            <Link
              color="var(--color-link-blue)"
              data-testid="unified-footer-fb"
              href="https://facebook.com/BetterLesson"
              marginRight=".5rem"
              rel="noreferrer"
              target="_blank"
              underline="none"
            >
              <Avatar
                alt="Facebook"
                src={Facebook}
                style={AvatarStyle}
                variant="square"
              />
            </Link>
          </ListItem>

          <ListItem disablePadding>
            <Link
              color="var(--color-link-blue)"
              data-testid="unified-footer-linkedin"
              href="https://www.linkedin.com/company/betterlesson/"
              marginRight=".5rem"
              rel="noreferrer"
              target="_blank"
              underline="none"
            >
              <Avatar
                alt="LinkedIn"
                style={AvatarStyle}
                src={LinkedIn}
                variant="square"
              />
            </Link>
          </ListItem>

          <ListItem disablePadding>
            <Link
              color="var(--color-link-blue)"
              data-testid="unified-footer-twitter"
              href="https://twitter.com/BetterLesson"
              rel="noreferrer"
              target="_blank"
              underline="none"
            >
              <Avatar
                alt="Twitter"
                style={AvatarStyle}
                src={Twitter}
                variant="square"
              />
            </Link>
          </ListItem>
        </List>
      </Grid>
    );
  };

  const renderLinks = (): JSX.Element[] => {
    return Links.map((link: FooterLink) => {
      return (
        <Grid item key={link.header.title} xs={12} sm={2} marginRight="1rem">
          <List>
            <ListItem disablePadding>
              <ListItemText>
                <Link
                  color="var(--color-grey-v2-1)"
                  data-testid="unified-footer-link"
                  fontFamily="Circular Std"
                  fontWeight={FONT_WEIGHT.BOLD}
                  fontSize=".875rem"
                  href={link.header.href}
                  underline="hover"
                >
                  {link.header.title}
                </Link>
              </ListItemText>
            </ListItem>

            {link.children.map((child: TitleLink) => {
              return (
                <ListItem
                  disablePadding
                  key={child.title}
                  sx={{ display: { xs: 'none', sm: 'inherit' } }}
                >
                  <ListItemText>
                    <Link
                      color="var(--color-grey-v2-1)"
                      data-testid="unified-footer-link-child"
                      fontSize=".875rem"
                      href={child.href}
                      underline="hover"
                    >
                      {child.title}
                    </Link>
                  </ListItemText>
                </ListItem>
              );
            })}
          </List>
        </Grid>
      );
    });
  };

  const renderLegal = () => {
    return (
      <aside>
        <small>
          <Typography
            color="var(--color-slate-1)"
            data-testid="unified-footer-legal"
            fontSize="0.6875rem"
            marginTop="1rem"
            tabIndex={0}
          >
            &copy; {new Date().getFullYear()} BetterLesson. All Rights Reserved.
          </Typography>
        </small>
      </aside>
    );
  };

  return (
    <footer
      data-testid="unified-footer"
      style={{ backgroundColor: 'var(--color-footer-bg)' }}
    >
      <Grid container padding="1rem">
        <Grid
          item
          xs={12}
          sm={3}
          xl={2}
          marginRight="1rem"
          marginTop=".25rem"
          sx={{ display: { xs: 'none', sm: 'inherit' } }}
        >
          <Link
            data-testid="unified-footer-logo"
            href={process.env.REACT_APP_LAB_URL}
          >
            <img alt="BetterLesson" width="165rem" src={BetterLessonLogo} />
          </Link>
        </Grid>

        {renderLinks()}
        {renderSocialMedia()}
      </Grid>

      <Grid
        container
        padding="1rem"
        style={{ backgroundColor: 'var(--color-footer-bg)' }}
      >
        <Grid item xs={12}>
          <Divider />
          {renderLegal()}
        </Grid>
      </Grid>
    </footer>
  );
};

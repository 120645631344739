import {
  ToastContainer as BaseToastContainer,
  Zoom,
  ToastContainerProps,
} from 'react-toastify';

export function ToastContainer(props: ToastContainerProps): JSX.Element {
  return (
    <BaseToastContainer
      icon={false}
      closeButton={false}
      autoClose={6000}
      hideProgressBar
      limit={3}
      pauseOnFocusLoss={false}
      pauseOnHover={false}
      position={'bottom-center'}
      transition={Zoom}
      style={{ width: 'auto' }}
      toastStyle={{ padding: 0, minHeight: 'unset' }}
      bodyStyle={{ padding: 0, margin: 0 }}
      {...props}
    />
  );
}
